<template>
	<div class="w-full h-full flex flex-col overflow-auto ">
		<div class="w-full h-full flex flex-col">
			<Header :classes="'items-end'">
				<div>
					<h1>Oversikt</h1>
				</div>
				
				<Tabs 
					class="flex-none justify-center md:justify-start"
					:tabs="tabs" 
					:active="activeSection" 
					@click="activeSection = $event.alias"
				/>
			</Header>
			
			<OverviewNavigation 
				v-if="activeSection == 'historic'"
				@loading="loading = $event"
				@fetchedStatsHistoric="statsHistoric = $event"
				class="flex-none px-6 pb-4 pt-20 bg-beige-darker"
			/>
			
			<div class="w-full h-full flex flex-col overflow-hidden">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<Overview 
						v-if="activeSection == 'current'"
						class="max-w-screen-lg mx-auto"
						:stats="stats" 
						:loading="loading"
						:userStats="true"
						userStatsMode="app"
					/>
					<Overview
						v-else-if="activeSection == 'historic'"
						class="max-w-screen-lg mx-auto"
						:stats="statsHistoric" 
						:loading="loading"
						:userStats="true"
						:useDepartmentTargets="false"
						userStatsMode="app"
					/>
				</div>
			</div>
		</div>
		
		<!-- <div class="md:p-12 h-full">
			<Overview 
				v-if="activeSection == 'current'"
				class="max-w-screen-lg mx-auto"
				:stats="stats" 
				:loading="loading"
				:userStats="true"
				userStatsMode="app">
				
				<nav class="max-w-xs mx-auto mb-6 px-3">
					<div class="rounded-lg flex overflow-hidden border-beige border">
						<div 
							v-for="tab in tabs"
							:key="tab.alias"
							@click="activeSection = tab.alias"
							class="cursor-default text-center flex-1 py-2"
							:class="[ activeSection == tab.alias ? 'bg-beige text-gray-dark' : '']">
							
							{{tab.name}}
						</div>
					</div>
				</nav>
			</Overview>
			<Overview
				v-else-if="activeSection == 'historic'"
				class="max-w-screen-lg mx-auto"
				:stats="statsHistoric" 
				:loading="loading"
				:userStats="true"
				:useDepartmentTargets="false"
				userStatsMode="app">
				
				<nav class="max-w-xs mx-auto mb-6 px-3">
					<div class="rounded-lg flex overflow-hidden border-beige border">
						<div 
							v-for="tab in tabs"
							:key="tab.alias"
							@click="activeSection = tab.alias"
							class="cursor-default text-center flex-1 py-2"
							:class="[ activeSection == tab.alias ? 'bg-beige text-gray-dark' : '']">
							
							{{tab.name}}
						</div>
					</div>
				</nav>
			</Overview>
		</div> -->
			
		<TransactionNotice />
    </div>
</template>

<script>
export default {
    components: {
        'Overview': () => import('@/components/stats/Overview.vue'),
		'OverviewNavigation': () => import('@/components/stats/OverviewNavigation.vue'),
        'TransactionNotice': () => import('@/components/TransactionNotice.vue'),
    },
    
    computed: {
        stats(){
            return this.$store.getters.getStats;
        },
		
		picture(){
			try {
				if (this.signedInUser.signInUserSession.idToken.payload.picture){
					return this.fileKeyToUrl( this.signedInUser.signInUserSession.idToken.payload.picture );
				}
				return null;
			} 
			catch (error) {
				console.log('no picture found. error:');
				console.error(error);
				return null;
			}
		},
		
    },
    
    data(){
        return {
            loading: null,
			overviewMode: 'current',
			statsHistoric: null,
			tabs: [
				{
					name: 'Gjeldende periode',
					alias: 'current',
				},
				{
					name: 'Historisk',
					alias: 'historic',
				},
			],
			activeSection: 'current',
        }
    },
	
    async mounted(){
        this.loading = 'fetching';
        await this.$store.dispatch('listStats');
        this.loading = null;
    },
}
</script>
